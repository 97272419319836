module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WHH95WG","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Simplity","short_name":"Simplity","start_url":"/","background_color":"#0C2340","theme_color":"#0C2340","display":"browser","icon":"src/assets/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"61772e1979b6d63dbf9f9d4093953431"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
